import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  id?:number;
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "CRM",
      icon: "",
      type: "sub",
      active: false,
      path: "crm",
      children: [
        {
          path: "crm/companies",
          icon: "./assets/SVGIcons/crm/Company.svg",
          title: "Companies",
          type: "link",
        },
        {
          path: "crm/contacts",
          icon: "./assets/SVGIcons/crm/contact.svg",
          title: "Contacts",
          type: "link",
        },
        {
          path: "crm/leads",
          icon: "./assets/SVGIcons/crm/phoneicon.svg",
          title: "Leads",
          type: "link",
        },
        {
          path: "crm/prospects",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Prospects",
          type: "link",
        },
        {
          path: "crm/customers",
          icon: "./assets/SVGIcons/crm/customers.svg",
          title: "Customers",
          type: "link",
        },
        // {
        //   path: "crm/tasks",
        //   icon: "./assets/SVGIcons/crm/task.svg",
        //   title: "Tasks",
        //   type: "link",
        // },
        {
          path: "crm/activityManager",
          icon: "./assets/SVGIcons/crm/task.svg",
          title: "Activity Manager",
          type: "link",
        },
        {
          path: "crm/archivedCompanies",
          icon: "./assets/SVGIcons/crm/Company.svg",
          title: "Archived Companies",
          type: "link",
        },

      ],
    },
    {
      title: "Sales",
      icon: "",
      type: "sub",
      active: false,
      children: [
        {
          path: "sales/requirements",
          icon: "./assets/SVGIcons/sales/requirements-icon.svg",
          title: "Requirements",
          type: "link",
        },
        {
          path: "sales/quotes",
          icon: "./assets/SVGIcons/sales/quotes-icon.svg",
          title: "Quotes",
          type: "link",
        },
        {
          path: "sales/sales-orders",
          icon: "./assets/SVGIcons/sales/sales-order.svg",
          title: "Sales Orders",
          type: "link",
        },
        {
          path: "sales/pick-and-ship",
          icon: "./assets/SVGIcons/sales/pick-ship.svg",
          title: "Pick & Ship",
          type: "link",
        },
        {
          path: "sales/invoices",
          icon: "./assets/SVGIcons/sales/Invoice.svg",
          title: "Invoices",
          type: "link",
        },
      ],
    },
    {
      title: "Procurement",
      icon: "",
      type: "sub",
      active: false,
      children: [
        {
          path: "procurement/vendor-list",
          icon: "./assets/SVGIcons/crm/Company.svg",
          title: "Vendor List",
          type: "link",
        },
        {
          path: "procurement/vendor-contact",
          icon: "./assets/SVGIcons/crm/Company.svg",
          title: "Vendor Contacts",
          type: "link",
        },
        {
          path: "procurement/sourcing",
          icon: "./assets/SVGIcons/crm/contact.svg",
          title: "Sourcing",
          type: "link",
        },
        {
          path: "procurement/purchase-orders",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Purchase Order",
          type: "link",
        },
      ],
    },
    {
      title: "Parts & Inventory",
      icon: "",
      type: "sub",
      active: false,
      children: [
        {
          path: "parts-and-inventory/parts-list",
          icon: "./assets/SVGIcons/crm/Company.svg",
          title: "Parts List",
          type: "link",
        },
        {
          path: "parts-and-inventory/inventory",
          icon: "./assets/SVGIcons/crm/contact.svg",
          title: "Inventory",
          type: "link",
        },
        {
          path: "parts-and-inventory/vendor-catalogs",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Vendor Catalogs",
          type: "link",
        },
      ],
    },
    {
      title: "Shipping & Receiving",
      icon: "",
      type: "sub",
      active: false,
      children: [
        {
          path: "shipping-and-receiving/receive-po",
          icon: "./assets/SVGIcons/crm/Company.svg",
          title: "Receive PO",
          type: "link",
        },
        {
          path: "shipping-and-receiving/pick-and-ship-so",
          icon: "./assets/SVGIcons/crm/contact.svg",
          title: "Pick & Ship SO",
          type: "link",
        },
        {
          path: "shipping-and-receiving/ship-and-receive-wo",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Ship & Receive WO",
          type: "link",
        },
        {
          path: "shipping-and-receiving/ship-vrma",
          icon: "./assets/SVGIcons/crm/phoneicon.svg",
          title: "Ship VRMA",
          type: "link",
        },
        {
          path: "shipping-and-receiving/receive-crma",
          icon: "./assets/SVGIcons/crm/customers.svg",
          title: "Receive CRMA",
          type: "link",
        },
      ],
    },
    {
      title: "Quality",
      icon: "",
      type: "sub",
      active: false,
      children: [
        {
          path: "quality/work-orders",
          icon: "./assets/SVGIcons/crm/Company.svg",
          title: "Work Orders",
          type: "link",
        },
        {
          path: "quality/customer-rma",
          icon: "./assets/SVGIcons/crm/contact.svg",
          title: "Customer RMA",
          type: "link",
        },
        {
          path: "quality/vendor-rma",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Vendor RMA",
          type: "link",
        },
        {
          path: "quality/ncm",
          icon: "./assets/SVGIcons/crm/phoneicon.svg",
          title: "NCM",
          type: "link",
        },
        {
          path: "quality/inspection-reports",
          icon: "./assets/SVGIcons/crm/customers.svg",
          title: "Inspection Reports",
          type: "link",
        },
      ],
    },
    {
      title: "Accounting",
      icon: "",
      type: "sub",
      active: false,
      children: [],
    },
    {
      title: "Reports",
      icon: "",
      type: "sub",
      active: false,
      children: [],
    },
    {
      title: "Admin",
      icon: "",
      type: "sub",
      active: false,
      children: [
        {
          path: "admin/user-management",
          icon: "./assets/SVGIcons/crm/Company.svg",
          title: "User Management",
          type: "link",
        },
        {
          path: "admin/look-up-tables",
          icon: "./assets/SVGIcons/crm/contact.svg",
          title: "Masters",
          type: "link",
        },
        {
          path: "admin/code-crosswalk",
          icon: "./assets/SVGIcons/crm/contact.svg",
          title: "Code Crosswalk",
          type: "link",
        },
        {
          path: "admin/reports",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Reports",
          type: "link",
        },
        {
          path: "admin/system-settings",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "System Settings",
          type: "link",
        },{
          path: "admin/company-settings",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Company Settings",
          type: "link",
        },
        {
          path: "admin/company-recycle-bin",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Company Recycle Bin",
          type: "link",
        },
        {
          path: "admin/contact-recycle-bin",
          icon: "./assets/SVGIcons/crm/newBreafCase.svg",
          title: "Contact Recycle Bin",
          type: "link",
        },
        // {
        //   path: "admin/WYSIWYG-editor",
        //   icon: "./assets/SVGIcons/crm/task.svg",
        //   title: "WYSIWYG",
        //   type: "link",
        // },
        // {
        //   path: "admin/angular-email-editor",
        //   icon: "./assets/SVGIcons/crm/task.svg",
        //   title: "Angular Email Editor",
        //   type: "link",
        // },

        {
          path: "admin/user-details",
          icon: "",
          title: "",
          type: "link",
          
        },
      ],
    },
  ];

  MEGAMENUITEMS: Menu[] = [
    {
      title: "Error Pages",
      type: "sub",
      active: true,
      children: [
        {
          path: "javascript:void(0);",
          title: "Error Page 400",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 401",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 403",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 404",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 500",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Error Page 503",
          type: "extLink",
        },
      ],
    },
    {
      title: "Authentication",
      type: "sub",
      active: false,
      children: [
        { path: "javascript:void(0);", title: "Login Simple", type: "extLink" },
        {
          path: "javascript:void(0);",
          title: "Login BG Image",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Login BG Video",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Simple Register",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Register BG Image",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Register BG Video",
          type: "extLink",
        },
      ],
    },
    {
      title: "Usefull Pages",
      type: "sub",
      active: false,
      children: [
        { path: "javascript:void(0);", title: "Search Pages", type: "extLink" },
        { path: "javascript:void(0);", title: "Unlock User", type: "extLink" },
        {
          path: "javascript:void(0);",
          title: "Forgot Password",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Reset Password",
          type: "extLink",
        },
        { path: "javascript:void(0);", title: "Maintenance", type: "extLink" },
      ],
    },
    {
      title: "Email templates",
      type: "sub",
      active: false,
      children: [
        {
          path: "http://admin.pixelstrap.com/cuba/theme/basic-template.html",
          title: "Basic Email",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/email-header.html",
          title: "Basic With Header",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/template-email.html",
          title: "Ecomerce Template",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/template-email-2.html",
          title: "Email Template 2",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/ecommerce-templates.html",
          title: "Ecommerce Email",
          type: "extTabLink",
        },
        {
          path: "http://admin.pixelstrap.com/cuba/theme/email-order-success.html",
          title: "Order Success",
          type: "extTabLink",
        },
      ],
    },
    {
      title: "Coming Soon",
      type: "sub",
      active: false,
      children: [
        {
          path: "javascript:void(0);",
          title: "Coming Simple",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Coming BG Image",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Coming BG Video",
          type: "extLink",
        },
      ],
    },
  ];

  LEVELMENUITEMS: Menu[] = [
    {
      path: "javascript:void(0);",
      title: "File Manager",
      icon: "git-pull-request",
      type: "extLink",
    },
    {
      title: "Users",
      icon: "users",
      type: "sub",
      active: false,
      children: [
        {
          path: "javascript:void(0);",
          title: "All Users",
          icon: "users",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "User Profile",
          icon: "users",
          type: "extLink",
        },
        {
          path: "javascript:void(0);",
          title: "Edit Profile",
          icon: "users",
          type: "extLink",
        },
      ],
    },
    {
      path: "javascript:void(0);",
      title: "Bookmarks",
      icon: "heart",
      type: "extLink",
    },
    {
      path: "javascript:void(0);",
      title: "Calender",
      icon: "calendar",
      type: "extLink",
    },
    {
      path: "javascript:void(0);",
      title: "Social App",
      icon: "zap",
      type: "extLink",
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
  megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
  levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);
}
